.contact-form-cont {
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    color: var(--primary-bg);
  }
  
  #contact {
    padding: 4rem 2rem;
  }

  .vertical_flex{
    display: grid; 
    gap: 10px; 
  }
  
  .contact_img_div {
    width: 150px !important;
    height: 150px !important;
  }
  
  .right_hr {
    float: right;
  }
  
  .image-bpx {
    width: 25px;
    height: 25px;
    overflow: hidden;
  }
  .purple {
    box-shadow: var(--purple-box-shadow) !important;
    background: inherit;
    border: none;
    cursor: pointer;
  }
  
  .contact-head-text {
    font-size: 40px;
    text-transform: capitalize;
  }
  
  .contact-head-textt { 
    text-transform: capitalize;
  }

  .outer{
    display: grid;
    gap: 15px;
    justify-content: baseline;
    align-items: baseline;
  }

  .contact-sub-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    font-size: 2rem;
  }
  
  .contact-sub-div::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0%;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background: none;
    opacity: 0.2;
  }
  
  .contact-sub-div-2 {
    flex: 1;
  }
  
  .my-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 2rem;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    z-index: 3;
  }
  
  .my-form::after {
    content: "";
    position: absolute; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -1;
    opacity: 0.2;
    padding: 2rem;
  }
  
  .form-div {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .form-div label {
    position: absolute;
    top: 0px;
    transform: translateY(30%);
    left: 10px;
    font-size: 16px;
    width: max-content;
    padding: 0.5rem 24px;
    cursor: pointer;
    transition: var(--transition);
  }
  
  option {
    background: inherit !important;
    -webkit-appearance: none;
    appearance: none;
  }
  
  :is(.form-div input:valid, .form-div textarea:valid) {
    background-color: i !important;
    -webkit-appearance: none;
    appearance: none;
  }
  
  :is(input:focus, input:valid, textarea:focus, textarea:valid) ~ label {
    transform: translateY(-25px) translateX(-15px);
    display: flex;
    align-items: center;
    font-size: 12px;
    /* z-index: 1; */
    color: #787672;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .form-div input,
  .form-div textarea {
    border-color: black;
    padding: 1.5rem;
    border-radius: 5px;
    background: inherit;
    color: var(--primary-bg);
    font-size: 13px;
    font-family: inherit !important;
    font-size: 14px;
  }
  
  .form-div textarea {
    min-height: 100px;
  }
  
  .form-div input:focus,
  .form-div textarea:focus,
  .form-div input:valid,
  .form-div textarea:valid {
    outline: none;
    background: inherit;
    -webkit-appearance: none;
    appearance: none;
  }

  .btnn {
    display: inline-block;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    cursor: pointer;
    color: #000000;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 10;
    background-color: transparent; 
  }
  
  .btnn:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: greenyellow;
    border-radius: 10rem;
    z-index: 8;
    transition: all .3s; 
    opacity: 0;
  }
  
  .btnn:hover:after {
    width: 100%;
    opacity: 1; 
  }
  
  .btnn:hover {
    color: #ababab;
  }
  
  
  

  .Message-me {
    text-align: right;
    font-size: 1.7rem;
  }
  
  .contact-links,
  #dark .contact-links {
    background-color: var(--primary-bg) !important;
    box-shadow: var(--purple-box-shadow) !important;
    color: var(--primary-text);
  }
 
  .thankyou_link,
  #dark .thankyou_link {
    color: red !important;
  }
  
  .error_message {
    color: var(--yellow);
    font-style: italic;
    padding: 0.3rem 0;
    animation: pulse 1s infinite;
  }
  
  .alt-text-yellow {
    color: var(--yellow);
  }
  
  .max_content {
    overflow: hidden;
  }
  
  /* ===========THANK YOU PAGE STYLING ========== */
  .thank-you {
    background-color: var(--yellow);
    border-radius: inherit;
    padding: 1rem 1rem;
    overflow: hidden;
    position: relative;
    color: #1b60a9 !important;
    flex-wrap: wrap;
  }
  
  .thank-you-home {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
  }
  
  .thankyou_img {
    flex: 1;
    animation: bounce_main 3s infinite;
    -webkit-animation: bounce_main 3s infinite;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .highlight_text{
    color: rgb(255, 23, 2);
    font-weight: bold; 
  }
 

  .thankyou_img {
    width: 100%;
    object-fit: contain;
    max-height: 150px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(35%);
    opacity: 0.3;
    animation: bounce 3s infinite;
  }
  
  .connect {
    display: flex;
    flex-direction: column;
  }
  
  .contact__cont_main {
    width: 80%;
    margin-bottom: 6rem;
  }
  
  .sys{
    display: flex;
    gap: 15px;
    align-items: baseline;
  }

  .connect-text {
    text-align: left;
    font-size: 15px;
    font-weight: 600;
  }
  
  .thank-you-desc {
    font-size: 18px;
  }
  
  .center-div {
    display: flex;
    justify-content: center;
  }
  
  #light .thankyou_link,
  #dark .thankyou_link {
    box-shadow: inset 1px 1px 10px #f3cc77, inset -1px -1px 10px #cfab5f !important;
    color: var(--alt-text) !important;
  }
  
  .thank_you_main,
  #dark .thank_you_main {
    width: 80%;
  }
  
  .a-b {
    box-shadow: var(--purple-box-shadow) !important;
  }
  
  /* ----------THANK YOU PAGE STYLING END ========== */
  #name {
    text-transform: capitalize;
  }
  
  #email {
    text-transform: lowercase;
  }
  
  @media (max-width: 900px) {
    .contact-form-cont {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 3rem 1rem;
      display: block;
    }
  
    .contact-sub-div {
      justify-content: center !important;
      text-align: center !;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      gap: 2rem;
      text-align: center;
      margin-bottom: 48px;
    }
  
    .center-it {
      justify-content: center;
      text-align: center;
    }
  
    .my-form {
      padding: 1rem !important;
    }
  
    .purple {
      margin: auto;
    }
  
    .contact-head-text {
      font-size: 30px;
    }
  
    .Message-me {
      font-size: 14px;
    }
  
    .left__align {
      justify-content: flex-start;
    }
  
    .thank-you-home h1 {
      font-size: 18px;
    }
  
    .left__align {
      justify-content: flex-start;
    }
  
    .thank-you-desc,
    .connect-text {
      font-size: 13px;
    }
  }
  
  @media (max-width: 600px) {
    .Message-me {
      font-size: 13px;
    }
  
    .form-div input,
    .form-div textarea {
      padding: 1rem;
    }
  
    .form-div label {
      font-size: 13px;
    }
  
    .submit-btn {
      padding: 0.5rem 2.5rem !important;
    }
  
    .contact__cont_main {
      width: 100%;
    }
  
    #contact {
      padding: 2rem 0;
    }
  
    .thank-you {
      padding: 1rem;
    }
  
    .connect-text {
      text-align: center;
    }
  
    :is(input:focus, input:valid, textarea:focus, textarea:valid) ~ label {
      transform: translateY(-13px) translateX(-10px);
      font-size: 10px;
    }
  }