@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500&family=Oswald:wght@200&family=Pixelify+Sans&family=Roboto+Condensed:wght@300&family=Roboto+Mono&family=Roboto:wght@100&display=swap');

.text1 {
    font-weight: bold;
    color: black;
}

.text2{
    font-style: italic;
    cursor: default;
    color: rgb(40, 31, 31);
}

.text3{
    font-family: 'Gabarito', cursive;
    font-family: 'Oswald', sans-serif;
    font-family: 'Pixelify Sans', cursive;
    font-family: 'Roboto', sans-serif;
    font-family: 'Roboto Condensed', sans-serif;
    font-family: 'Roboto Mono', monospace;
    color: rgb(152, 94, 2)
}

.text2:hover{
    color:rgb(6, 90, 118)
}

.grid1 {
    display: grid;
    gap: 40px;
}

.grid2 {
    display: grid;
    gap: 7px;
}

 

.flex31 {
    display: flex;
    gap: 25px;
}

.flex32 {
    display: flex;
    gap: 15px;
    align-items: center;
}

.out-inn{
    display: block;
}

.outeredu {
    display: grid;
    margin-bottom: 18rem;
}

.circle-edu {
    width: 23px;  
    height: 23px;  
    background-color: #f00;  
    border-radius: 50%;  
    left: 100%;
    transform: translateX(-50%);
    box-shadow: 10 10 10px rgba(0, 0, 0, 0.5);
}

.st-line-flex{
    display: flex;
    align-items: center;
}

.line {
    height: 500px;
    width: 2px;
    border-radius: 10%;
    background-color: aquamarine;
}

.report-card {
    color:hotpink
}

.report-card:hover{
    color: rgb(255, 77, 0);
    cursor: pointer;
}

@media (max-width : 900px) {
    .outeredu{
        padding: 13px;
    }

}