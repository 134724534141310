.socials{
    text-align: center;
    background-color: white;
    color: black;
    padding-left: 23%;
    padding-bottom: 4rem;
}

.circle_socials{
    width: 240px;
    height: 240px;
    background-color: #1C1D20;
    color: white;
    border-radius: 50%;
    position: absolute;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.p_of_social{
    margin: 0px;
    font-size: 16px;
    font-weight: 300;
    position: relative;
    z-index: 1;
}

.flex_socials{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 280px;
    padding-bottom: 9rem;
}

.hide{
    display: none;
}

@media (max-width : 600px) {
    .socials{
        padding-left: 5%;
    }
    .flex_socials{
        display: grid;
        grid-template-columns: auto auto;
        gap: 160px;
        padding-bottom: 5rem;
    }

    .circle_socials{
        width: 150px;
        height: 150px;
    }
    
    .hide{
        display: contents;
    }
}