@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&family=Work+Sans:wght@6=700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap');

#home {
  padding: 0;
}

.selfName{
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 10px;
}

.hero-main-text{
  font-family: 'Space Grotesk', sans-serif;
  font-family: 'Work Sans', sans-serif;
  color: #c7cad6;
}

.college_desc{
  margin-top: 20px;
  margin-left: 5px;
  color: #c6c9d5;
  margin-bottom: 20px;
  width: 70%;
  font-family: 'Roboto Mono', monospace;
}
 
.leftfram{
  width: 60%; 
  margin-bottom: 10%;
}

.rightfram{
  width: 40%; 
  margin-bottom: 10%;
}

.hero-main-cont {
  width: 100%;
  display: flex;
  height: 800px; 
  gap: 5%;
  padding: 1.5rem;
  align-self: start;
  align-self: flex-end;
  margin-top: 6rem;
  justify-content: center;  
}
 
.cyann{
  color: cyan
}

.hero-flex {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  overflow: hidden;
  transition: var(--transition);
}

.hero-main-text {
  font-size: 54px;
}

.hero-img {
  width: 90%;
  height: 60%;
  /* border-radius: 50%; */
}

.hero-sub-cont-1 {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  /* gap: 10px; */
  flex: 1;
}

.aniHero{
  /* background-image: url(../../assets/hero_background.jpg); */
  background-color: #000000;
}

.alt-text2 {
  color: #ffffff;
}
.hero-subtext {
  color: var(--black);
  display: flex;
  gap: 5px;
  font-size: 15px;
}

#dark .hero-subtext {
  color: var(--yellow);
}

.what_am_i {
  font-size: 20px;
}

.head-span-text {
  color: var(--alt-text);
  font-size: 13px;
}

.btn {
   background: inherit;
   font-family: inherit;
   font-size: inherit;
   border: none;
   cursor: pointer;
   color: #c7cad6;
}

.btn-div {
  display: flex;
  gap: 20px;
  margin-top: 1rem;
}

.wave {
  font-size: 40px;
}

@media (max-width: 1000px) {
  .hero-main-text {
    font-size: 35px;
  }

  .hero-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .leftfram{
    width: 90%;
    margin-left: 25px;
    font-weight: 1.5rem;
  }

  .hero-main-cont{
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .rightfram{
      margin-left: 5%;
  }

  .hidediv{
    display: none;
  }

  .hero-img{
    width: 200%;
    height: auto;
  }

  .college_desc{
    width: 80%;
    font-size: small;
  }

  .aniHero{
    margin-left: 0;
    padding: 0; 
  }

  .hero-flex {
    grid-template-columns: 1fr;
    padding: 4rem 0 0;
  }

  .left_align {
    display: none;
  }

  .hero-main-text {
    font-size: 25px;
  }

  .what_am_i {
    font-size: 15px;
  }

  .hero-subtext {
    font-size: 12px;
  }
}

.move_to_top{ 
  right: 10px;
  top: 10%;
  position: absolute; 
} 
 