.description {
    display: flex;
    justify-content: center;
    margin-bottom: 18rem;
    color: black;
    user-select: none;
  }
   
  .description .body p {
    margin: 0px;
  }
  .description .body p:nth-of-type(1) {
    font-size: 20px;
    gap: 8px;
    line-height: 1.3;
  }
  .description .body p:nth-of-type(1) span {
    margin-right: 3px;
  }
  .description .body p:nth-of-type(1) .mask {
    overflow: hidden;
    display: inline-flex;
  }
   

  .description2 {
    display: flex;  
    margin-bottom: 18rem;
    color: black; 
  }
   
  .description2 .body p {
    margin: 0px;
  }
  .description2 .body p:nth-of-type(1) {
    font-size: 20px;
    gap: 8px;
    line-height: 1.3;
  }
  .description2 .body p:nth-of-type(1) span {
    margin-right: 50px;
  }
  .description2 .body p:nth-of-type(1) .mask2 {
    overflow: hidden;
    display: inline-flex;
  }
   


 