.project-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
    justify-content: center; 
    align-items: center;
 }

  

$finalHeight: 100px;
$finalWidth: 3 * $finalHeight;
$scrollBarHeight: 1px;

::-webkit-scrollbar {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

::-webkit-scrollbar-button {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

.aniProject{
  height: 750px;
}

.horizontal-scroll-wrapper {
  left: 0;
  width: 1350px;
  height: 1300px;
  // margin: 10px;
  padding-top: $scrollBarHeight;
  overflow-y: auto;
  overflow-x: hidden;
  transform: rotate(-90deg) translateY(-$finalHeight);
  & > div {
    background: rgb(255, 255, 255);
    transform: rotate(90deg);
  }
}

.squares {
  padding: 0 0 0 0;
  & > div {
    width: 350px;
    height: 1500px;
    margin: 10px 0;
  }
}

 .project-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    justify-content: center;
    align-items: center;
    align-items: center;
 }
 
 .project-desc {
    z-index: 2;
 }
 
 .project-hr {
    width: 70%;
 }
 
 .project-head-text {
    font-size: 30px;
 }

 @media (max-width: 600px){
  .horizontal-scroll-wrapper {
    left: 0;
    width: auto;
    height:fit-content;
    margin-top: 0;
    padding-top: 0;
    transform: rotate(0) translateY(0);
    & > div {
      background: rgb(255, 255, 255);
      transform: rotate(0);
    }
  }
  
  .squares {
    padding: 0 0 0 0;
    & > div {
      width: auto;
      height: auto;
      margin: 10px 0;
    }
  }

  .aniProject{
    height: fit-content;
  }
 }