.nav-cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.3rem 0.1rem;
    user-select: none;
    top: 0;
    background: var(--primary-bg);
    left: 0;
    transition: var(--transition);
  }
  
  #dark .nav-cont {
    background: var(--primary-bg-dark);
  }
  
  .logo_img {
    height: 43px !important;
    width: 53px !important;
    padding: 3px !important;
    border-radius: 50%;
  }
 
  #dark .logo_text {
    box-shadow: var(--box-shadow-dark);
  }
  
  .links-container {
    display: flex;
    gap: 1.25rem;
    list-style: none;
    transition: var(--transition);
  }
  
  .links {
    padding: 0.5rem 0.8rem;
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    font-weight: 400;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: inherit;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  #dark .links {
    box-shadow: var(--box-shadow-dark);
  }
  
  .links:focus,
  .links:active {
    box-shadow: var(--inset-box);
  }
  
  #dark .links:focus,
  #dark .links:active {
    box-shadow: var(--inset-box-dark);
  }
  
  .tab-icon {
    display: none;
    cursor: pointer;
  }
  
  @media (max-width: 1000px) {
    .tab-icon {
      display: flex;
      z-index: 10;
      box-shadow: var(--box-shadow);
      padding: 10px;
      font-size: 15px;
      border-radius: 5px;
      transition: var(--transition);
      font-weight: 900;
    }
  
    #dark .tab-icon {
      box-shadow: var(--box-shadow-dark);
    }
  
    .dark-tab-icon {
      box-shadow: var(--inset-box) !important;
    }
  
    #dark .dark-tab-icon {
      box-shadow: var(--inset-box-dark) !important;
    }
  
    .links-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      top: 63px;
      right: 0%;
      width: 100%;
      background: var(--primary-bg);
      opacity: 0;
      transform: translateY(-200%);
      transition: var(--transition);
      padding: 2rem;
      box-shadow: var(--box-shadow);
    }
  
    #dark .links-container {
      background: var(--primary-bg-dark);
      box-shadow: var(--box-shadow-dark);
    }
  
    .show-tab {
      opacity: 1;
      transform: translateY(0);
      z-index: 50 !important;
    }
  
    .links {
      width: 50%;
      text-align: center;
      padding: 1rem;
    }
  }
  
  @media (max-width: 600px) {
    .nav-cont {
      padding: 0.5rem 0.9rem;
      position: fixed;
      z-index: 101;
    }
  
    .logo {
      font-size: 15px;
    }
  
    .logo_img {
      height: 40px !important;
      width: 40px !important;
    }
  
    .links-container {
      top: 56px;
    }
  
    .links {
      width: 90vw;
      justify-content: flex-start !important;
      border-radius: 0;
    }
  }