* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  scroll-behavior: smooth;
}

::selection {
  background-color: #e8171e;  
  color: white; 
}

body #dark {
  background-color: white;
  backdrop-filter: blur(100%);
  color: var(--white); 
  background-size: contain;
}

.max-width {
  max-width: 1440px;
  margin: auto;
}

.left_align {
  justify-content: flex-start !important;
}

.self_name{
  color: #e8680c;
  font-weight: bold;
}

.footer{
  margin-left: 100px;
}

.scroll_to_top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  font-size: 30px;
  animation: bouncing 2s infinite;
  cursor: pointer;
  color: var(--yellow);
  height: min-content;
  z-index: 4;
}

#dark .scroll_to_top {
  color: var(--alt-text) !important;
}
 
::-webkit-scrollbar {
  width: 5px;
}
 
body::-webkit-scrollbar-track {
  background: var(--white);
}

body::-webkit-scrollbar-thumb {
  height: 3px;
  background: var(--primary-text);
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb:hover {
  background: var(--yellow);
}

.active {
  box-shadow: var(--inset-box);
}

.giticon{
  display: flex;
  gap: 5px;
}

.flex1{
  display: flex;
  gap: 10px;
}

#dark .active {
  box-shadow: var(--inset-box-dark) !important;
}

.footer {
  gap: 0 !important;
  margin: 0;
}

.footer :nth-child(1) {
  text-transform: capitalize;
}

.footer :nth-child(2) {
  font-weight: 500;
}

.TNP {
  font-weight: 700;
}

#dark .TNP {
  color: var(--yellow);
}

@media (width <=600px) {
  .scroll_to_top {
    font-size: 20px;
    bottom: 20px;
    right: 20px;
  }

  body #dark {
    background-size: 180%;
  }
} 

@keyframes bouncing {
  0% {
    transform: translateY(0px);
    filter: grayscale(10%) brightness(40%) sepia(100%) hue-rotate(-50deg)
      saturate(600%) contrast(0.8);
  }

  50% {
    transform: translateY(-10px);
    filter: invert(70%) sepia(65%) saturate(332%) hue-rotate(0deg)
      brightness(104%) contrast(96%);
  }

  100% {
    transform: translateY(0px);
    filter: invert(30%) sepia(30%) saturate(2132%) hue-rotate(241deg)
      brightness(96%) contrast(88%);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(1px) translateX(35%);
  }

  50% {
    transform: translateY(-7px) translateX(35%) rotate(10deg);
  }

  100% {
    transform: translateY(0px) translateX(35%);
  }
}

@keyframes bounce_main {
  0% {
    transform: translateY(1px);
  }

  50% {
    transform: translateY(-7px) rotate(10deg);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 9px;
  background: var(--red);
  background-color: #e8680c;
  transform-origin: 0%;
  z-index: 101;
}

.active {
  background-color: rgb(9, 151, 183);
}
 
