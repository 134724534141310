@import url('https://fonts.googleapis.com/css2?family=Gabarito&family=Oswald:wght@200&family=Pixelify+Sans&family=Roboto+Condensed:wght@300&family=Roboto+Mono&family=Roboto:wght@100&display=swap');

.about-main-container {
   max-width: 1540px;
}  

.lassted{
   margin-top: 10px;
}

.blue{
   color: blue;
}

.btn-div{
   margin-left: 10px;
}

.aniAbout{
      background-color: white;
}

.about-me-text {
   max-width: 600px;
   text-justify: inter-word;
   margin-bottom: 10px;   
   font-family: 'Gabarito', cursive;
   font-family: 'Oswald', sans-serif;
   font-family: 'Pixelify Sans', cursive;
   font-family: 'Roboto', sans-serif;
   font-family: 'Roboto Condensed', sans-serif;
   font-family: 'Roboto Mono', monospace;
   color: black;
}

.about-me-textt {
   font-family: 'Gabarito', cursive;
   font-family: 'Oswald', sans-serif;
   font-family: 'Pixelify Sans', cursive;
   font-family: 'Roboto Condensed', sans-serif;
   font-family: 'Roboto Mono', monospace;
   font-size: 0.8rem;
}

.about-container {
   display: flex;
   flex-wrap: wrap;
   /* height: inherit; */
   gap: 30px;
   align-items: center;
   justify-content: center;
   color: var(--black); 
   margin-bottom: 15rem;
   margin-top: 5rem;
}

#dark .about-container {
   color: var(--white);
}

.ach-ie {
   color: rgb(12, 212, 5);
   font-size: 1.3rem;
   margin-bottom: 5px;
}

.ull {
   text-decoration: underline;
}

.cyan {
   color: rgb(20, 10, 214)
}
 

.para-c{
   color: rgb(236, 12, 12);
}

 
.about-image {
   width: 100%;
}

.flex {
   display: flex;
   align-items: center;
   gap: 10px;

}

.column {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.abt-image-div {
   width: 80px;
   height: 80px;
}

.abt-header {
   font-weight: 800;
}

.alt-text {
   color: var(--alt-text);
}

.about-sub-cont {
   max-width: 600px;
   display: grid;
   gap: 10px;
}

.short-hr {
   border: none;
   border-bottom: 5px solid var(--primary-text);
   width: 20%;
   border-radius: 7px;
   margin-bottom: 10px;
}

#dark .short-hr {
   border-color: var(--yellow);
}

 
@keyframes buttonPulse {
   
   50% { 
      color: var(--alt-text);
   }
   
}
@media (max-width : 600px) {
   .aniAbout{
      padding-left: 5px;
   }
   
   .about-me-textt{
      margin-left: 10px;
   }
   
}
