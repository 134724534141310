.project-card-container {
    width: 80vw; 
    padding: .5rem;
    min-width: 70vw;
    /* box-shadow: var(--box-shadow); */
    /* border-radius: 5px; */
    overflow: hidden;
    /* transition: var(--transition); */
    /* border-radius: 15px; */
    height: 5px; 
    padding-top: 250px;
 }

.project_image{
  width: 560px;
  height: 315px;
  overflow: hidden;
}

 .iframe_container{
   width: 60rem;
 }

 .project-card-container>iframe{
   width:45rem;
   height:25rem;
 }
 
 .inside-des-project {
   font-weight: bold;
   font-size: medium;
   margin-left: 25px; 
   color: rgb(246, 255, 0);
 }

 #dark .project-card-container {
    /* box-shadow: var(--box-shadow-dark); */
    background-color:rgb(255, 255, 255);
 }
 
.mobile{
  display: none;
}

 .hiddenClass {
    display: none;
 }
 
 .project-card-image {
    width: 100%;
    height: 100%;
 }
 
 .icons-link {
    width: 100%;
 }
 
.link_text{
   margin-left: 30px;
}
 

 .project--title {
    font-size: 18px;
    font-weight: bold;
    color: rgb(214, 128, 0);
    text-decoration: underline;
 }
 
 
 .project-links {
    border-radius: 50%;
    box-shadow: var(--box-shadow);
    /* padding: .5rem; */
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-bg);
    text-decoration: none;
    /* z-index: 3; */
 }
 
 #dark .project-links {
    background-color: var(--primary-bg-dark);
    box-shadow: var(--box-shadow-dark);
 }
 
 .project--title {
    font-size: 17px;
    margin-left: 5px;
    font-size: 1.4rem;
 }

 .project--title-ins {
      color: orange;
      padding: 5px 0px 3px 8px;
      font-size: 1.7rem;
}
 
 .project-desc {
    font-size: 14px;
    text-justify: distribute-all-lines;
 }
 
 .makeTextBlack{
   color: rgb(255, 255, 255);
   margin-left: 25px;
   margin-top: 4px;
   margin-right: 4px;
 }

 .stacks_description_cont {
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: 500;
    /* z-index: 2; */
    color: rgb(154, 0, 0);
 }
 
 .stacks_description {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    padding: 5px;
    color: black;
 }
 
 .link_text {
    font-size: 10px;
    position: relative;
    left: -18px;
    /* z-index: 2; */
    /* padding: .3rem .3rem .3rem 1rem !important; */
    align-items: center;
    display: flex;
    color: rgb(16, 0, 85);
    font-weight: bold;
    animation: scaleAnimation 2s linear infinite;
 }

 @keyframes scaleAnimation {
   0% {
     transform: scale(1);
   }
   50% {
     transform: scale(1.1);
   }
   100% {
     transform: scale(1);
   }
 }

 .link_text:hover{
    color: rgb(255, 159, 5);
 }


 
 @media (width <=900px) {
   .project-card-container{
      width:100%
   }
   .project-card-container>iframe{
      width:100%;
      height:calc(100vw * 0.5625);
    }
 }

 @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400');
 
.layer {
  z-index: -1;
  position: absolute;
  top: 250px; 
  width: 51rem;
  min-height: 26rem; 
  border-radius: 5px;
  background: black;
  opacity: 0;
  transform: scale(0.1);
  transition: all 0.5s ease;
  > * {
    margin: 0;
  }
}

.project_heading{
   justify-content: center;
   align-items: center;
   margin-bottom: 7rem;
}

.clicked {
  transition: all 0.5s ease;
  transform: scale(1);
  z-index: 100;
  opacity: 1;
  p, a {
    opacity: 1;
  }
}
 
.close{ 
    position: absolute;
  right: 15px;
  top: 10px;
  color: #902121;
  cursor: pointer;
}
 
.git_inline{
   display: flex;
   gap: 30px;
   margin-left: 7px;
}

.Buton{
   background-color: red;
   color: black;
   height: 25px;
   width: 30px;
}


 
 
 /*
      https://codepen.io/alexbodin/pen/XWrqpxB
  */
 .animated-button {
   background : rgb(0, 255, 149);
   padding: 10px 20px;
   margin: 5px;
   min-width: 12rem;
   display: inline-block;
   -webkit-transform: translate(0%, 0%);
           transform: translate(0%, 0%);
   overflow: hidden;
   cursor: pointer;
   color: #000000;
   font-size: 20px;
   text-align: center;
   text-transform: uppercase;
   text-decoration: none;
 }
 
 .animated-button::before {
   content: '';
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   background-color: #8592ad;
   opacity: 0;
   -webkit-transition: .2s opacity ease-in-out;
   transition: .2s opacity ease-in-out;
 }
 
 .animated-button:hover::before {
   opacity: 0.2;
 }
 
 .animated-button span {
   position: absolute;
 }
 
 .animated-button span:nth-child(1) {
   top: 0px;
   left: 0px;
   width: 100%;
   height: 2px;
   background: -webkit-gradient(linear, right top, left top, from(rgba(8, 20, 43, 0)), to(#2662d9));
   background: linear-gradient(to left, rgba(8, 20, 43, 0), #2662d9);
   -webkit-animation: 2s animateTop linear infinite;
           animation: 2s animateTop linear infinite;
 }
 
 @-webkit-keyframes animateTop {
   0% {
     -webkit-transform: translateX(100%);
             transform: translateX(100%);
   }
   100% {
     -webkit-transform: translateX(-100%);
             transform: translateX(-100%);
   }
 }
 
 @keyframes animateTop {
   0% {
     -webkit-transform: translateX(100%);
             transform: translateX(100%);
   }
   100% {
     -webkit-transform: translateX(-100%);
             transform: translateX(-100%);
   }
 }
 
 .animated-button span:nth-child(2) {
   top: 0px;
   right: 0px;
   height: 100%;
   width: 2px;
   background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 20, 43, 0)), to(#2662d9));
   background: linear-gradient(to top, rgba(8, 20, 43, 0), #2662d9);
   -webkit-animation: 2s animateRight linear -1s infinite;
           animation: 2s animateRight linear -1s infinite;
 }
 
 @-webkit-keyframes animateRight {
   0% {
     -webkit-transform: translateY(100%);
             transform: translateY(100%);
   }
   100% {
     -webkit-transform: translateY(-100%);
             transform: translateY(-100%);
   }
 }
 
 @keyframes animateRight {
   0% {
     -webkit-transform: translateY(100%);
             transform: translateY(100%);
   }
   100% {
     -webkit-transform: translateY(-100%);
             transform: translateY(-100%);
   }
 }
 
 .animated-button span:nth-child(3) {
   bottom: 0px;
   left: 0px;
   width: 100%;
   height: 2px;
   background: -webkit-gradient(linear, left top, right top, from(rgba(8, 20, 43, 0)), to(#2662d9));
   background: linear-gradient(to right, rgba(8, 20, 43, 0), #2662d9);
   -webkit-animation: 2s animateBottom linear infinite;
           animation: 2s animateBottom linear infinite;
 }
 
 @-webkit-keyframes animateBottom {
   0% {
     -webkit-transform: translateX(-100%);
             transform: translateX(-100%);
   }
   100% {
     -webkit-transform: translateX(100%);
             transform: translateX(100%);
   }
 }
 
 @keyframes animateBottom {
   0% {
     -webkit-transform: translateX(-100%);
             transform: translateX(-100%);
   }
   100% {
     -webkit-transform: translateX(100%);
             transform: translateX(100%);
   }
 }
 
 .animated-button span:nth-child(4) {
   top: 0px;
   left: 0px;
   height: 100%;
   width: 2px;
   background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 20, 43, 0)), to(#2662d9));
   background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #2662d9);
   -webkit-animation: 2s animateLeft linear -1s infinite;
           animation: 2s animateLeft linear -1s infinite;
 }
 
 @-webkit-keyframes animateLeft {
   0% {
     -webkit-transform: translateY(-100%);
             transform: translateY(-100%);
   }
   100% {
     -webkit-transform: translateY(100%);
             transform: translateY(100%);
   }
 }
 
 @keyframes animateLeft {
   0% {
     -webkit-transform: translateY(-100%);
             transform: translateY(-100%);
   }
   100% {
     -webkit-transform: translateY(100%);
             transform: translateY(100%);
   }
 }
 
  
 
 
$openSans: 'Open Sans', Helvetica, Arial, sans-serif;

.buttons {
  // margin-top: 50px;
  text-align: center;
  // border-radius:30px;
}

$cyan: #0505A9;
$dark: #FFFFFF;
$borderW: 2px;

.blob-btn {
  $numOfBlobs: 4;
  z-index: 1;
  position: relative;
  padding: 10px 20px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  color: $cyan;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  // border-radius:30px;
  
  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: $borderW solid $cyan;
    // border-radius:30px;
  }
  
  &:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: $borderW*1.5;
    top: $borderW*1.5;
    width: 100%;
    height: 100%;

    transition: all 0.3s 0.2s;
    // border-radius:30px;
  }
  
  &:hover {
    color: $dark;
    // border-radius:30px;
    
    &:after {
      transition: all 0.3s;
      left: 0;
      top: 0;
      border-radius:30px;
    }
  }
  
  &__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // border-radius:30px;
    background:#ffffff;
  }
  
  &__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url('#goo');
  }
  
  &__blob {
    position: absolute;
    top: $borderW;
    width: 100% / $numOfBlobs;
    height: 100%;
    background: $cyan;
    // border-radius: 100%;
    transform: translate3d(0,150%,0) scale(1.7);
    transition: transform 0.45s;
    
    @supports(filter: url('#goo')) {
      transform: translate3d(0,150%,0) scale(1.4);
    }
    
    @for $i from 1 through $numOfBlobs {
      &:nth-child(#{$i}) {
        left: ($i - 1) * (120% / $numOfBlobs);
        transition-delay: ($i - 1) * 0.08s;
      }
    }
    
    .blob-btn:hover & {
      transform: translateZ(0) scale(1.7);
      
      @supports(filter: url('#goo')) {
        transform: translateZ(0) scale(1.4);
      }
    }
  }
  
}

@media (max-width:600px) {
   .web{
    display: none;
   }

   .mobile{
    display: block;
    color: black;
   }

   .inside-des-project{
    color: #000000;
   }

   .project_image{
    // display: none;
    width: 330px;
    height: 325px;
  }
  
}