@import url('https://fonts.cdnfonts.com/css/clash-display');
:root {
  --font_clash: 'Clash Display', sans-serif;
  --primary-bg: #e0e0e0;
  --primary-text: #7d44a8;
  --alt-text: #76B0EF;
  --box-shadow: 5px 5px 10px #b5b5b5, -2px -2px 10px #fff;
  --inset-box: inset 5px 10px 8px #b5b5b5, inset -5px -5px 8px #fff;
  --transition: .5s ease-in-out;
  --black: #636363;
  --purple-box-shadow: 1px 1px 10px #000000, -1px -1px 10px #000000;
  --purple-inset-box: inset 1px 1px 10px #44255c, inset -1px -1px 10px #7e45aa;
  --white: #e4d8d8;
  --yellow: #fabb37;
 
  --primary-bg-dark: #241e1e;
  --box-shadow-dark: 5px 5px 5px #0b0b0b, -2px -2px 5px #000000;
  --inset-box-dark: inset 5px 1px 15px #110d0d, inset -3px -5px 15px #110d0d;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important; 
}

section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  overflow: hidden;
}

.switch {
  font-size: 20px;
  cursor: pointer;
  transform: rotate(45deg) translateY(5px);
  z-index: 10;
}

#dark .switch {
  color: var(--white);
}

@media (min-width : 1500px) {
  section {
    padding: 10rem 0 5rem;
    height: max-content !important;
  }
}

@media (max-width : 1440px) {
  section {
    padding: 7rem 2rem;
  }
}

@media (max-width : 600px) {

  section {
    padding: 6rem 0;
  }
}