.skills-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
    height: 90%;
    width: 100%;
    overflow: hidden;
  } 

  .meter{
    width: 100%;
    height: 10px;
    color: 5px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
  }

  
  .encircle{ 
    height: 10px;
    border-radius: 10px;
    background-color: rgb(254, 0, 0);
  }

  .temp{
    display: grid;
    gap: 10px;
  }

  .right_skills{
      width: 65vw;
      display: flex; 
      justify-content: space-between;
      color: black;
  }
  
  .yourSkill{
    font-weight: bold;
    font-size: 1.2rem;
  }

  .outerskills{
    display: grid;
    gap: 10px;
  }

  @media (max-width: 600px) {
    .right_skills{
      padding-left: 0;
      margin-left: 0;
      width: 58vw;
    }
  }

  
